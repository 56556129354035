import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedRoutingModule } from './shared-routing.module';
import { StoryComponent } from './story/story.component';
import {
  NbContextMenuModule,
  NbInputModule,
  NbDatepickerModule,
  NbSelectModule,
  NbButtonModule,
  NbCheckboxModule,
} from '@nebular/theme';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PhotoUploadComponent } from './photo-upload/photo-upload.component';
import { AngularFireStorageModule } from '@angular/fire/storage';
// NGXS STATE
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { UserState } from './state/user/user.state';
import { environment as env } from '../../environments/environment';
import { JobState } from './state/jobs/jobs.state';
import { NgxsModule } from '@ngxs/store';
import { StoryState } from './state/stories/stories.state';
import { JobService } from 'app/services/job.service';
import { ProfileState } from './state/profile/profile.state';
import { JobComponent } from './job/job.component';

const components = [StoryComponent, PhotoUploadComponent, JobComponent];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NbContextMenuModule,
    NbInputModule,
    NbSelectModule,
    NbButtonModule,
    NbDatepickerModule,
    NbCheckboxModule,
    ReactiveFormsModule,
    CKEditorModule,
    AngularFireStorageModule,
    NgxsModule.forFeature([UserState, JobState, StoryState, ProfileState]),
    // NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ logger: console, collapsed: false }),
  ],
  exports: [
    ...components,
    CommonModule,
    NgxsModule,
    NgxsLoggerPluginModule,
    // NgxsReduxDevtoolsPluginModule,
  ],
})
export class SharedModule {

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [JobService],
    };
  }
}

/**
 * Special module that is only used when calling forRoot()
 */
// @NgModule({
//   imports: [
//     NgxsModule.forFeature([UserState, JobState]),

//     // import so that the module calling forRoot() gets hold of all the stuff too.
//     SharedModule,
//   ],
//   exports: [
//     SharedModule,
//   ],
// })
// export class RootSharedComponentsModule {
//   constructor() {
//     console.log('constructor RootSharedComponentsModule');
//   }
// }
