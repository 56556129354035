import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import { ProfileService } from 'app/services/profile.service';
import { throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { JobState } from '../jobs/jobs.state';
import { Job } from '../models/job.model';
import { Profile } from '../models/profile.model';
import { ProfileActions } from './profile.actions';

export interface ProfileStateModel {
  userProfile: Profile;
  selectedProfile?: Profile;
  loading?: boolean;
  error?: any;
}

@State<ProfileStateModel>({
  name: 'profiles',
  defaults: {
    userProfile: null,
    selectedProfile: null,
    loading: null,
    error: null,
  },
})
@Injectable()
export class ProfileState {
  // TODO: Handle ERROR in views
  // @Dispatch()

  constructor(private profileService: ProfileService) {}

  @Selector()
  public static getUserProfile(state: ProfileStateModel) {
    return state.userProfile;
  }
  @Selector()
  public static getSelectedProfile(state: ProfileStateModel) {
    return state.selectedProfile;
  }

  // @Selector([StoryState.allStories, JobState.selectedJob])
  // public static currentJobsStories(allStories: Story[], selectedJob: Job) {
  //   if (allStories?.length > 0) {
  //     const userStories = allStories.filter(story => story.jobId == selectedJob.id);
  //     return userStories;
  //   }
  //   return [];
  // }

  // { cancelUncompleted: true }
  @Action(ProfileActions.GetUserProfile)
  public getProfile(ctx: StateContext<ProfileStateModel>, { payload }) {
    // call to story service for all allStories by user_id
    return this.profileService.getProfileByUserId(payload).pipe(
      take(1),
      tap((profile: Profile[]) => {
        ctx.patchState({
          userProfile: profile[0],
          loading: false,
        });
      }),
      catchError(error => {
        ctx.dispatch(new ProfileActions.GetUserProfileFailure(error));
        ctx.patchState({
          error: error,
          loading: false,
        });
        return throwError('Error Getting User Profile: ', error);
      }),
    );
  }
}
