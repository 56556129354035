<div class="job">
  <div class="view-job" *ngIf="selectedJob?.mode === 'view'">
    <div class="job-header">
      <div class="job-header-title" *ngIf="selectedJob.employer">{{ selectedJob.employer }}</div>
    </div>
    <div class="job-content"></div>
  </div>

  <div class="edit-job" *ngIf="selectedJob?.mode === 'edit' || selectedJob?.mode === 'new'">
    <div class="job-header">
      <div class="job-header-title">{{ jobForm?.value.employer || 'New Job'}}</div>
      <!-- <div class="job-header-date">{{ jobForm?.value.startDate | date:'MM-dd-yyyy' }}</div> -->

      <nb-select placeholder="Select Job Color" (selectedChange)="setFormData('color', $event)"  [(selected)]="selectedColor">
        <nb-option value="blue">Blue</nb-option>
        <nb-option value="gray">Gray</nb-option>
        <nb-option value="green">Green</nb-option>
        <nb-option value="purple">Purple</nb-option>
        <nb-option value="red">Red</nb-option>
        <nb-option value="yellow">Yellow</nb-option>
      </nb-select>
    </div>

    <div class="job-content">
      <form [formGroup]="jobForm" (ngSubmit)="onSubmit()">
        <div class="title-date">
          <div class="title-date-container">
            <input type="text" nbInput fullWidth [placeholder]="selectedJob.employer || 'Name of employer'"
              formControlName="employer">
            <input type="text" nbInput fullWidth [placeholder]="selectedJob.roleTitle || 'What was your job title?'"
              formControlName="roleTitle">
            <div class="date-container">
              <nb-checkbox class="current-job" status="basic" [checked]="true" (checkedChange)="toggleCurrentJob($event)">Current Job</nb-checkbox>

              <input nbInput fullWidth class="date-input" placeholder="When did you start this job?"
                [nbDatepicker]="dateTimePickerStart" formControlName="startDate">
              <nb-datepicker #dateTimePickerStart></nb-datepicker>
              <div class="input-gap" *ngIf="!currentJob"></div>
              <input nbInput fullWidth class="date-input" placeholder="When did you leave this job?"
                [nbDatepicker]="dateTimePickerEnd" formControlName="endDate" *ngIf="!currentJob">
              <nb-datepicker #dateTimePickerEnd></nb-datepicker>
            </div>
          </div>
        </div>

        <div class="description-editor">
          <ckeditor class="ck-editor" [editor]="Editor" [config]="{ height: '320' }"
            [data]="selectedJob.summary || '<p>Summarize your responsibilities and achievements at this job...</p>'"
            (change)="setFormData('description', $event)" tagName="textarea"></ckeditor>
        </div>

        <div class="public-job">
          <nb-checkbox status="basic" [checked]="true" (checkedChange)="togglePublicJob($event)">Make Job Public (Visible on Profile)</nb-checkbox>
        </div>

        <!-- PHOTO AND VIDEO STUFF /// NOT USEFUL FOR JOBS [FROM STORY] -->

        <!-- <div class="type-color">
            <div class="left">
              <nb-select class="full-width" placeholder="Select Theme Type" [(selected)]="selectedType" (selectedChange)="setFormData('type', $event)" >
                <nb-option value="text">Text</nb-option>
                <nb-option value="photo">Photo</nb-option>
                <nb-option value="video">Video</nb-option>
              </nb-select>
            </div>
            <div class="spacer"></div>
            <div class="right">
              <nb-select fullWidth placeholder="Select Theme Color" (selectedChange)="setFormData('label', $event)"  [(selected)]="selectedColor">
                <nb-option value="blue">Blue</nb-option>
                <nb-option value="gray">Gray</nb-option>
                <nb-option value="green">Green</nb-option>
                <nb-option value="purple">Purple</nb-option>
                <nb-option value="red">Red</nb-option>
                <nb-option value="yellow">Yellow</nb-option>
              </nb-select>
            </div>
          </div>
          <div class="photo-video">
            <div class="outline">
              <ngx-photo-upload (completedUrl)="setPhotoUrl($event)"></ngx-photo-upload>
            </div>
            <div class="spacer"></div>
            <div class="outline">
              Add Video
            </div>
          </div> -->
      </form>
    </div>
  </div>
  <div class="story-footer">
    <button class="save" (click)="onSubmit()">Save</button>
    <button class="cancel" (click)="cancelJob()">Cancel</button>
    <button class="delete" (click)="onDelete(selectedJob.id)" *ngIf="selectedJob?.mode !== 'new'">Delete Job</button>
  </div>
</div>
