<div class="story">
  <div class="view-story" *ngIf="selectedStory.mode === 'view'">
    <div class="story-header">
      <div class="story-header-title" *ngIf="selectedStory.title">{{ selectedStory.title }}</div>
    </div>
    <div class="story-content"></div>
  </div>

  <div class="edit-story" *ngIf="selectedStory.mode === 'edit' || selectedStory.mode === 'new'">
    <div class="story-header">
      <div class="story-header-title">{{ storyForm?.value.title || 'New Story'}}</div>
      <div class="story-header-date">{{ storyForm?.value.createdDate | date:'MM-dd-yyyy' }}</div>
    </div>

    <div class="story-content" *ngIf="selectedJob$ | async as job">
      <form [formGroup]="storyForm" (ngSubmit)="onSubmit(job.id)">
        <div class="title-date">
          <input type="text" nbInput fullWidth [placeholder]="selectedStory.title || 'Story Title'" formControlName="title">
          <input nbInput fullWidth class="date-input" [placeholder]="selectedStory.createdDate.seconds" [nbDatepicker]="dateTimePicker" formControlName="createdDate">
          <nb-datepicker #dateTimePicker></nb-datepicker>
        </div>
        <div class="description-editor">
          <ckeditor class="ck-editor" [editor]="Editor" [config]="{ height: '320' }" [data]="selectedStory.summary || '<p>Tell your story...</p>'" (change)="setFormData('summary', $event)" tagName="textarea"></ckeditor>
        </div>
        <div class="type-color">
          <div class="left">
            <nb-select class="full-width" placeholder="Select Theme Type" [(selected)]="selectedType" (selectedChange)="setFormData('type', $event)" >
              <nb-option value="text">Text</nb-option>
              <nb-option value="photo">Photo</nb-option>
              <nb-option value="video">Video</nb-option>
            </nb-select>
          </div>
          <div class="spacer"></div>
          <div class="right">
            <nb-select fullWidth placeholder="Select Theme Color" (selectedChange)="setFormData('label', $event)"  [(selected)]="selectedColor">
              <nb-option value="blue">Blue</nb-option>
              <nb-option value="gray">Gray</nb-option>
              <nb-option value="green">Green</nb-option>
              <nb-option value="purple">Purple</nb-option>
              <nb-option value="red">Red</nb-option>
              <nb-option value="yellow">Yellow</nb-option>
            </nb-select>
          </div>
        </div>
        <!-- <div class="photo-video">
          <div class="outline">
            <ngx-photo-upload (completedUrl)="setPhotoUrl($event)"></ngx-photo-upload>
          </div>
          <div class="spacer"></div>
          <div class="outline">
            Add Video
          </div>
        </div> -->
      </form>
    </div>
  </div>
  <div class="story-footer"  *ngIf="selectedJob$ | async as job">
    <button class="save" (click)="onSubmit(job.id)">Save</button>
    <button class="cancel" (click)="cancelStory()">Cancel</button>
    <button class="delete" *ngIf="selectedStory.mode === 'edit'" (click)="onDelete()">Delete Story</button>
  </div>
</div>
