import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext, Store } from '@ngxs/store';
import { Story } from '../models/story.model';
import { JobService } from 'app/services/job.service';
import { StoryMigration } from 'app/services/migrations/story.migration';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Job } from '../models/job.model';
import { JobActions } from './jobs.actions';

export interface JobStateModel {
  jobs: Job[];
  selectedJob: Job;
  error: any;
}

@State<JobStateModel>({
  name: 'jobs',
  defaults: {
    jobs: null,
    selectedJob: null,
    error: null,
  },
})
@Injectable()
export class JobState {
  // TODO: Handle ERROR in views

  constructor(private jobService: JobService, private migration: StoryMigration) {}

  @Selector()
  public static allJobs(state: JobStateModel) {
    return state.jobs;
  }
  @Selector()
  public static selectedJob(state: JobStateModel) {
    return state.selectedJob;
  }

  @Action(JobActions.GetAll, { cancelUncompleted: true })
  public getAllJobs(ctx: StateContext<JobStateModel>, { payload }) {
    // call to job service for all jobs by user_id
    return this.jobService.getJobsById(payload).pipe(
      tap(jobs => {
        ctx.patchState({
          jobs,
        });
      }),
      catchError(error => {
        ctx.dispatch(new JobActions.GetAllFailure(error));
        ctx.patchState({
          jobs: [],
        });
        return throwError('Error Getting All Jobs: ', error);
      }),
    );
  }

  @Action(JobActions.GetAllFailure, { cancelUncompleted: true })
  public getAllFailure(ctx: StateContext<JobStateModel>, { payload }) {
    ctx.patchState({
      error: payload,
    });
  }

  @Action(JobActions.SetSelectedJob, { cancelUncompleted: true })
  public setSelectJobById(ctx: StateContext<JobStateModel>, { payload }) {
    const job: Job = payload;
    if (job) {
      this.jobService.selectedJob$.next(job);
      ctx.patchState({
        selectedJob: job,
      });
    }
    // TODO: ERROR HANDLING
  }

  // @Action(JobActions.GetSingle, { cancelUncompleted: true })
  // public getSingleJob(ctx: StateContext<JobStateModel>, { payload }) {
  //   const userModel = ctx.getState();
  //   // ctx.setState({
  //   //   ...userModel,
  //   //   user: payload
  //   // });
  // }

  @Action(JobActions.Update, { cancelUncompleted: true })
  public updateSingleJob(ctx: StateContext<JobStateModel>, { payload }) {
    const job = payload;
    // push updated job to db
    this.jobService.updateJob(job)
    .catch(err => ctx.dispatch(new JobActions.GetAllFailure(err)));
    // TODO: add Failure Action
    // ctx.dispatch(new JobActions.GetAll())
  }

  @Action(JobActions.Create)
  public createJob(ctx: StateContext<JobStateModel>, { payload }) {
    const job: Job = payload;
    // push updated job to db
    this.jobService.createJob(job)
    .catch(err => ctx.dispatch(new JobActions.GetAllFailure(err)));
    // TODO: add Failure Action
    // ctx.dispatch(new JobActions.GetAll())
  }

  @Action(JobActions.Delete)
  public deleteSingleJob(ctx: StateContext<JobStateModel>, { payload }) {
    const jobId = payload;
    // push updated job to db
    this.jobService.deleteJob(jobId)
    .catch(err => ctx.dispatch(new JobActions.GetAllFailure(err)));
    // TODO: add Failure Action
  }
}
