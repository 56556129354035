import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import { StoryService } from 'app/services/story.service';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { JobState } from '../jobs/jobs.state';
import { Job } from '../models/job.model';
import { Story } from '../models/story.model';
import { StoryActions } from './stories.actions';

export interface StoryStateModel {
  allStories: Story[];
  selectedStory?: Story;
  error?: any;
}

@State<StoryStateModel>({
  name: 'stories',
  defaults: {
    allStories: null,
    selectedStory: null,
    error: null,
  },
})
@Injectable()
export class StoryState {
  // TODO: Handle ERROR in views
  // @Dispatch()

  constructor(private storyService: StoryService) {}

  @Selector()
  public static allStories(state: StoryStateModel) {
    return state.allStories;
  }
  @Selector()
  public static selectedStory(state: StoryStateModel) {
    return state.selectedStory;
  }

  @Selector([StoryState.allStories, JobState.selectedJob])
  public static currentJobsStories(allStories: Story[], selectedJob: Job) {
    if (allStories?.length > 0 && selectedJob.id) {
      const userStories = allStories.filter(story => story.jobId === selectedJob.id);
      return userStories;
    }
    if (allStories?.length > 0 && !selectedJob.id) {
      return allStories;
    }
    return [];
  }

  // { cancelUncompleted: true }
  @Action(StoryActions.GetAll)
  public getAllStories(ctx: StateContext<StoryStateModel>, { payload }) {
    // call to story service for all allStories by user_id
    return this.storyService.getStoriesByUserId(payload).pipe(
      tap((story: Story[]) => {
        ctx.setState({
          allStories: story,
        });
        if (story[0]) {
          ctx.dispatch(new StoryActions.SetSelectedStory(story[0]));
        }
      }),
      catchError(error => {
        ctx.dispatch(new StoryActions.GetAllFailure(error));
        ctx.patchState({
          allStories: [],
        });
        return throwError('Error Getting All Stories: ', error);
      }),
    );
  }

  // { cancelUncompleted: true }
  @Action(StoryActions.GetAllByJobId)
  public getAllStoriesByJobId(ctx: StateContext<StoryStateModel>, { payload }) {
    const state = ctx.getState();
    const stories = state.allStories.filter(story => {
      if (story.jobId === payload) {
        return story;
      }
    });
    return stories;
  }

  @Action(StoryActions.GetAllFailure, { cancelUncompleted: true })
  public getAllFailure(ctx: StateContext<StoryStateModel>, { payload }) {
    ctx.patchState({
      error: payload,
    });
  }

  @Action(StoryActions.UpdateFailure, { cancelUncompleted: true })
  public updateFailure(ctx: StateContext<StoryStateModel>, { payload }) {
    ctx.patchState({
      error: payload,
    });
  }

  @Action(StoryActions.SetSelectedStory, { cancelUncompleted: true })
  public setSelectedStoryById(ctx: StateContext<StoryStateModel>, { payload }) {
    const story: Story = payload;
    if (story) {
      ctx.patchState({
        selectedStory: story,
      });
    }
    // TODO: ERROR HANDLING
  }

  @Action(StoryActions.Create)
  public createStory(ctx: StateContext<StoryStateModel>,  payload: { payload: {data, jobId} } ) {
    const story: Story = payload.payload.data;
    const jobId: string = payload.payload.jobId;

    // push updated job to db
    // TODO:  MAKE SEPARATE ERROR SLICE IN STATE
    this.storyService.createStory(story, jobId)
      .catch(err => ctx.dispatch(new StoryActions.UpdateFailure(err)));
  }

  @Action(StoryActions.Update, { cancelUncompleted: true })
  public updateSingleStory(ctx: StateContext<StoryStateModel>, { payload }) {
    const storyId = payload.id;
    const data = payload;
    // push updated job to db
    this.storyService.updateStory(storyId, data).catch(err => ctx.dispatch(new StoryActions.GetAllFailure(err)));
  }

  @Action(StoryActions.Delete)
  public deleteSingleStory(ctx: StateContext<StoryStateModel>, { payload }) {
    const storyId = payload.storyId;
    const uuid = payload.uuid;
    // push updated job to db
    this.storyService.deleteStory(uuid, storyId).then(() => ctx.dispatch(new StoryActions.GetAll(uuid)))
    .catch(err => ctx.dispatch(new StoryActions.GetAllFailure(err)));
  }
}
