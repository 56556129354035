import { Component } from '@angular/core';
import { NbThemeService } from '@nebular/theme';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar">
        <ng-content select="nb-menu"></ng-content>
        <nb-sidebar-footer>
          <div class="selector-title">Theme: </div>
          <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" hero size="small" status="primary">
            <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
          </nb-select>
        </nb-sidebar-footer>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <!-- <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer> -->
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'aqua',
      name: 'Aqua',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'dark';

  constructor(private themeService: NbThemeService) {}

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }
}
