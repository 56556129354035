import { NbMenuItem } from '@nebular/theme';

export const MINI_ACCOUNT_MENU: NbMenuItem[] = [
  {
    title: 'Subscriptions',
    icon: 'credit-card-outline',
    link: '/auth/login',
  },
  {
    title: 'Your Profile',
    icon: 'person-outline',
    link: '/home/profile',
  },
  {
    title: 'Log Out',
    icon: 'power-outline',
    link: '/auth/logout',
  },
];
