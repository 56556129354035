import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { NbAuthService } from '@nebular/auth';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'ngx-photo-upload',
  templateUrl: './photo-upload.component.html',
  styleUrls: ['./photo-upload.component.scss'],
})
export class PhotoUploadComponent implements OnInit {
  @Output() completedUrl = new EventEmitter();

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  downloadURL;
  user;

  constructor(private afStorage: AngularFireStorage, private auth: NbAuthService, private userService: UserService) { }

  ngOnInit(): void {
    this.user = this.userService.currentUser;
  }

  upload = (event) => {
    // create a random id
    const randomId = Math.random().toString(36).substring(2);

    // create a reference to the storage bucket location
    this.ref = this.afStorage.ref(this.userService.getStoryMediaPath('photos') + randomId);

    // the put method creates an AngularFireUploadTask
    // and kicks off the upload
    this.task = this.ref.put(event.target.files[0]);
    // observe upload progress
    this.uploadProgress = this.task.percentageChanges();

    // get notified when the download URL is available
    this.task.snapshotChanges().pipe(
      finalize(() => {
        this.downloadURL = this.ref.getDownloadURL().pipe(
          tap(url => this.completedUrl.emit(url || null)),
        );
      }),
    )
    .subscribe();
  }

}
