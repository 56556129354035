import { Story } from '../models/story.model';

export namespace StoryActions {

  export class GetAll {
    public static readonly type = '[Stories] Get All';
    // payload: user_id
    constructor(public payload: string) { }
  }

  export class GetAllByJobId {
    public static readonly type = '[Stories] Get All By JobId';
    // payload: jobId
    constructor(public payload: string) { }
  }
  export class GetAllFailure {
    public static readonly type = '[Stories] Get All Failure';
    // payload: error
    constructor(public payload: string) { }
  }

  export class SetSelectedStory {
    public static readonly type = '[Stories] Set Selected Story';
    constructor(public payload: Story) { }
  }

  // export class GetSingle {
  //   public static readonly type = '[Stories] Get User';
  //   constructor(public payload: string) { }
  // }

  export class Create {
    public static readonly type = '[Stories] Create';
    constructor(public payload: {jobId: string, data: Story}) { }
  }

  export class Update {
    public static readonly type = '[Stories] Update';
    // payload: storyId/story
    constructor(public payload: Story) { }
  }

  export class UpdateFailure {
    public static readonly type = '[Stories] Update Failure';
    // payload: error
    constructor(public payload: string) { }
  }


  export class Delete {
    public static readonly type = '[Stories] Delete';
    // payload: storyId
    constructor(public payload: {uuid: string, storyId: string}) { }
  }

  export class DeleteStoryFromJob {
    public static readonly type = '[Stories] Delete StoryId from Job';
    constructor(public payload: {jobId: string, storyId: string}) { }
  }
}
