export namespace UserActions {

  export class SignIn {
    public static readonly type = '[User] Sign In';
    constructor(public payload: string) { }
  }
  export class SignInFailure {
    public static readonly type = '[User] Sign In Failure';
    constructor(public payload: string) { }
  }

  export class GetCurrentUser {
    public static readonly type = '[User] Get Current User';
    constructor(public payload: string) { }
  }

  export class SignOut {
    public static readonly type = '[User] Sign Out';
    constructor(public payload: string) { }
  }
}
