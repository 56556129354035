import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { JobService } from 'app/services/job.service';
import { Job } from '../state/models/job.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { JobActions } from '../state/jobs/jobs.actions';

export interface ViewJob extends Job {
  mode: 'edit' | 'view' | 'new';
}

@Component({
  selector: 'ngx-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
})
export class JobComponent implements OnInit {
  @Dispatch()
  newJob = (payload) => new JobActions.Create(payload)
  @Dispatch()
  updateJob = (payload) => new JobActions.Update(payload)
  @Dispatch()
  deleteJob = (payload) => new JobActions.Delete(payload)

  @Input() public selectedJob: ViewJob;

  public Editor = ClassicEditor;

  jobForm: FormGroup;

  currentJob = true;
  publicJob = true;

  colorItems = [
    { title: 'None', value: 'none' },
    { title: 'Blue', value: 'blue' },
    { title: 'Green', value: 'green' },
    { title: 'Gray', value: 'gray' },
    { title: 'Purple', value: 'purple' },
    { title: 'Red', value: 'red' },
    { title: 'Yellow', value: 'yellow' },
  ];

  constructor(public dialogRef: NbDialogRef<any>, public datepipe: DatePipe, public jobService: JobService) { }

  ngOnInit(): void {
    this.buildForm();
  }

  public buildForm() {
    if (this.selectedJob?.mode) {
      this.jobForm = new FormGroup({
        roleTitle: new FormControl(this.selectedJob?.mode === 'edit' ? this.selectedJob.roleTitle : null),
        summary: new FormControl(this.selectedJob['description'] || this.selectedJob.summary || 'Job Summary'),
        startDate: new FormControl(this.selectedJob.mode === 'edit' ? this.selectedJob.startDate : null),
        endDate: new FormControl(this.selectedJob.mode === 'edit' ? this.selectedJob.endDate : null),
        roleType: new FormControl(this.selectedJob.roleType),
        employer: new FormControl(this.selectedJob.employer),
        currentJob: new FormControl(this.selectedJob.currentJob || true),
        public: new FormControl(this.selectedJob.public || true),
        order: new FormControl(this.selectedJob.order || 0),
        color: new FormControl(this.selectedJob.color || 'none'),
      });
    } else {
      this.jobForm = new FormGroup({
        roleTitle: new FormControl(this.selectedJob?.roleTitle ? this.selectedJob.roleTitle : null),
        summary: new FormControl(this.selectedJob?.summary || this.selectedJob?.summary || 'Job Summary'),
        startDate: new FormControl(this.selectedJob?.mode === 'edit' ? this.selectedJob?.startDate : null),
        endDate: new FormControl(this.selectedJob?.mode === 'edit' ? this.selectedJob?.endDate : null),
        roleType: new FormControl(this.selectedJob?.roleType),
        employer: new FormControl(this.selectedJob?.employer),
        currentJob: new FormControl(this.selectedJob?.currentJob || true),
        public: new FormControl(this.selectedJob?.public || true),
        order: new FormControl(this.selectedJob?.order || 0),
        color: new FormControl(this.selectedJob?.color || 'none'),
      });
    }

    // init values for checkboxes
    this.currentJob = this.jobForm.get('currentJob').value;
    this.publicJob = this.jobForm.get('public').value;
  }

  cancelJob() {
    this.dialogRef.close();
  }

  toggleCurrentJob(checked: boolean) {
    this.currentJob = checked;
    this.jobForm.patchValue({
      currentJob: checked,
    });
  }
  togglePublicJob(checked: boolean) {
    this.publicJob = checked;
    this.jobForm.patchValue({
      public: checked,
    });
  }

  onSubmit() {
    const data: Job = this.jobForm.value;
    // Add required identifiers
    data.id = this.selectedJob.id;
    data.uid = this.selectedJob.uid;
    data.experience = this.selectedJob.experience;

    if (this.selectedJob?.mode === 'edit') {
      this.updateJob(data);
    } else {
      this.newJob(data);
    }
    this.dialogRef.close();
    // fire success message
  }

  // TODO: USE STORY ID
  onDelete(jobId: string) {
    this.deleteJob(jobId);
    this.dialogRef.close();
    // fire successful delete msg
  }

  get getStoryDate() {
    const rawDate = this.jobForm.value.createdDate;
    const formattedDate = this.datepipe.transform(rawDate, 'yyyy-MM-dd');
    return formattedDate;
  }

  setFormData(formProperty: string, formData: any) {
    let data;
    if (formData.editor) {
      data = formData.editor.getData();
    } else {
      data = formData;
    }
    this.jobForm.get(formProperty).patchValue(data);
  }

  cancelStory() {
    this.dialogRef.close();
  }

  // TODO: FIX PATH TO IMAGR, CHECK IT IS CORRECT
  setPhotoUrl($event) {
    if ($event) {
      this.jobForm.get('photoUrl').patchValue($event);
    }
  }
}
