import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap, map, shareReplay } from 'rxjs/operators';
import { Board } from '../models/board.model';
import { Observable, Subscription, BehaviorSubject, of } from 'rxjs';
import * as firebase from 'firebase/app';
import { Story } from 'app/shared/state/models/story.model';
import { StoryStateModel } from 'app/shared/state/stories/stories.state';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { StoryActions } from 'app/shared/state/stories/stories.actions';
import { firestore } from 'firebase/app';
import Timestamp = firestore.Timestamp;
import { Profile } from 'app/shared/state/models/profile.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  protected path = 'stories';

  stories$: Observable<Story[]>;
  selectedStory$: BehaviorSubject<Story> = new BehaviorSubject(null);

  @Dispatch()
  private removeStoryIdFromJob = (jobId: string, storyId: string) => {
    new StoryActions.DeleteStoryFromJob({jobId, storyId});
  }

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    protected store: Store,
  ) {}



  getProfileByUserId(ID: string) {
    if (ID) {
      return this.db.collection<Profile>('profiles', ref =>
        ref.where('uid', '==', ID),
      )
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Profile;
          Object.keys(data).filter(key => data[key] instanceof Timestamp)
                        .forEach(key => data[key] = data[key].toDate());
          // Getting the auto-genned doc id and assigning it to the story id
          const id = a.payload.doc.id;
          return { id, ...data };
        }),
        shareReplay(1),
      ),
      );
      // .valueChanges();
      // .valueChanges({ idField: 'id' });
    } else {
      return of([]);
    }
  }

  /**
   * Creates a new story for the current user
   */
  async createStory(data: Story, jobId: string) {
    const user = await this.afAuth.currentUser;
    // TODO: THESE ARE UNDEFINED
    let id;
    await this.db.collection('stories').add({
      ...data,
      uid: user.uid,
      jobId,
    })
    .then(docref => {
      id = docref.id;
    });
    return id;
  }

  /**
   * Get a single story
   */
  getStory(storyId: string) {
    return this.db
      .collection('story')
      .doc(storyId);
  }

  /**
   * Updates a board/job
   */
  updateStory(storyId: string, data: Story[]) {
    return this.db
      .collection('stories')
      .doc(storyId)
      .update(data);
  }

  /**
   * Run a batch write to change the priority of each board for sorting
   */
  sortStories(jobs: Story[]) {
    const db = firebase.firestore();
    const batch = db.batch();
    const refs = jobs.map(j => db.collection('stories').doc(j.id));
    refs.forEach((ref, idx) => batch.update(ref, { priority: idx }));
    batch.commit();
  }

  /**
   * Delete board
   */
  deleteStory(jobId: string, storyId: string) {
    // this.removeStoryIdFromJob(jobId, storyId);
    return this.db
      .collection('stories')
      .doc(storyId)
      .delete();
  }

  /**
   * Remove a specifc task from the board
   */
  removeStoryFromJob(stories: string[], jobId: string) {
    return this.db
      .collection('boards')
      .doc(jobId)
      .update({
        stories,
      });
  }
}
