<!-- <div class="photo-upload-container">
    <button nbButton>Upload Photo</button>
    <input type="file" (change)="upload($event)" accept=".png,.jpg" />
    <progress max="100" [value]="(uploadProgress | async)"></progress>
</div> -->
<div class="phto-upload-container">
    <input type="file" (change)="upload($event)" accept=".png,.jpg" />

    <progress *ngIf="uploadProgress|async as prog" max="100" [value]="prog"></progress>
    <div class="result" *ngIf="downloadURL | async; let uploadSrc">
        <a [href]="uploadSrc">You just uploaded this file!</a>
    </div>

    <div class="container" *ngIf="downloadURL | async; let url">
        <img [src]="url" alt="Image from AngularFireStorage">
    </div>
</div>