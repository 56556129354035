import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public currentUser = null;

  constructor() { }

  public setCurrentUser(user) {
    this.currentUser = user;
  }

  public getStoryMediaPath(mediaType) {
    if (this.currentUser && mediaType === 'photos' || mediaType === 'videos') {
      return `${this.currentUser.user_id}/stories/${mediaType}/`;
    } else {
      return null;
    }
  }
}
