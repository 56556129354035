import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { JobActions } from '../../../shared/state/jobs/jobs.actions';
import { UserState } from '../../../shared/state/user/user.state';
import { JobState } from 'app/shared/state/jobs/jobs.state';
import { Job } from 'app/shared/state/models/job.model';
import { StoryActions } from 'app/shared/state/stories/stories.actions';

@Component({
  selector: 'ngx-job-selector',
  templateUrl: './job-selector.component.html',
  styleUrls: ['./job-selector.component.scss'],
})
export class JobSelectorComponent implements OnInit {
  private user = this.store.selectSnapshot(UserState.getCurrentUser);
  public allJobs$;
  @Select(JobState.allJobs) jobsFromState$: any;
  @Select(JobState.selectedJob) selectedJob$: any;
  public selectedId$;
  public jobArray;
  // public selectedJob$ = this.jobService.selectedJob$.pipe(
  //   map(job => job?.id),
  // );
  constructor(private store: Store) { }

  ngOnInit(): void {
    // get all jobs
    if (this.user?.user_id) {
      this.store.dispatch(new JobActions.GetAll(this.user.user_id));
      this.store.dispatch(new StoryActions.GetAll(this.user.user_id));
    }

    // get selected job
    this.selectedId$ = this.selectedJob$.pipe(
      map((job: Job) => job?.id),
    );

    // return a list of id/title pairs for select dropdown
    this.allJobs$ = this.jobsFromState$.pipe(
      map((jobs: Job[]) => {
        if (jobs?.length > 0) {
          this.jobArray = jobs;
          const selectedJob = this.jobArray[0];
          this.store.dispatch(new JobActions.SetSelectedJob(selectedJob));

          return jobs.map(job => {
            // TODO: MAKE STORY ACTIVE

            // if (!this.jobService.selectedJob$.value) {
            //   if (job.tasks?.length > 0) {
            //     job.tasks[0]['active'] = true;
            //   }

            //   this.setSelectedJob(job.id);
            // }

            return { title: job.employer, id: job.id };
          });
        }
        return jobs;
      }),
    );
  }

  public setSelectedJob(jobId) {
    const filtered = this.jobArray.filter(job => job.id === jobId );
    this.store.dispatch(new JobActions.SetSelectedJob(filtered[0]));
    // console.log('selected job: ', filtered[0]);
    // this.jobService.selectedJob$.next(filtered[0]);
  }
}
