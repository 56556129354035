import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, shareReplay } from 'rxjs/operators';
import { Board } from '../../models/board.model';
import { Observable, of } from 'rxjs';
import { Story } from '../../shared/state/models/story.model';
import * as firebase from 'firebase/app';
import { StoryActions } from 'app/shared/state/stories/stories.actions';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';

@Injectable({
  providedIn: 'root',
})
export class StoryMigration {
  // private storiesStore = this.store
  @Dispatch()
  newStory = (jobId: string, story: Story) => new StoryActions.Create({jobId, data: story})

  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore) { }

  public run(jobId: string, data: Story[]) {
    if (data?.length) {

      setTimeout(() => {
        this.newStory(jobId, data[0]);
      }, 650);
    }
  }

  getStoriesByUserId(ID: string) {
    if (ID) {
      return this.db.collection<Story>('stories', ref =>
        ref.where('uid', '==', ID).orderBy('priority'),
      )
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data() as Story;
          // Getting the auto-genned doc id and assigning it to the story id
          const id = a.payload.doc.id;
          return { id, ...data };
        }),
        shareReplay(1),
      ),
      );
      // .valueChanges();
      // .valueChanges({ idField: 'id' });
    } else {
      return of([]);
    }
  }

  /**
   * Creates a new story for the current user
   */
  async createStory(data: Story, jobId: string) {
    const user = await this.afAuth.currentUser;
    return this.db.collection('stories').add({
      ...data,
      uid: user.uid,
      jobId,
    });
  }

  /**
   * Get a single story
   */
  getStory(storyId: string) {
    return this.db
      .collection('story')
      .doc(storyId);
  }

  /**
   * Updates a board/job
   */
  updateStory(storyId: string, data: Story[]) {
    return this.db
      .collection('stories')
      .doc(storyId)
      .update(data);
  }

  /**
   * Run a batch write to change the priority of each board for sorting
   */
  sortStories(jobs: Story[]) {
    const db = firebase.firestore();
    const batch = db.batch();
    const refs = jobs.map(j => db.collection('stories').doc(j.id));
    refs.forEach((ref, idx) => batch.update(ref, { priority: idx }));
    batch.commit();
  }

  /**
   * Delete board
   */
  deleteStory(storyId: string) {
    return this.db
      .collection('stories')
      .doc(storyId)
      .delete();
  }

  /**
   * Remove a specifc task from the board
   */
  removeStoryFromJob(stories: string[], jobId: string) {
    return this.db
      .collection('boards')
      .doc(jobId)
      .update({
        stories,
      });
  }
}
