import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { switchMap, map, shareReplay } from 'rxjs/operators';
import { Observable, Subscription, BehaviorSubject, of } from 'rxjs';
import { Story } from '../shared/state/models/story.model';
import * as firebase from 'firebase/app';
import { Job } from 'app/shared/state/models/job.model';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  jobs$: Observable<Job[]>;

  // used with job-selector
  selectedJob$: BehaviorSubject<Job> = new BehaviorSubject(null);
  singleJob$: Subscription;
  allJob$: Subscription;
  constructor(private afAuth: AngularFireAuth, private db: AngularFirestore) { }

  getJobsById(id: string) {
    if (id) {
      return this.db.collection<Job>('jobs', ref =>
        ref.where('uid', '==', id).orderBy('order'),
      )
      .snapshotChanges()
        .pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as Job;
            const docId = a.payload.doc.id;
            return { docId, ...data };
          }),
          shareReplay(1),
        ),
      );
    } else {
      return of([]);
    }
  }

  getUserJobs() {
    return this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.db.collection<Job>('boards', ref =>
            ref.where('uid', '==', user.uid).orderBy('priority'),
          )
          .snapshotChanges().pipe(
            map(actions => actions.map(a => {
              const data = a.payload.doc.data() as Job;
              const id = a.payload.doc.id;
              return { id, ...data };
            }),
            ),
          );
          // .valueChanges();
          // .valueChanges({ idField: 'id' });
        } else {
          return [];
        }
      }),
      shareReplay(1),
      // map(boards => boards.sort((a, b) => a.priority - b.priority))
    );
  }

  // TODO: NEEDS TO BE REACTIVE
  // getJobs(id?: string): Subscription {
  //   return this.allJob$ = this.getUserJobs().subscribe(boards => {
  //     this.jobs$ = of(boards);
  //     if (id) {
  //       this.jobs$.subscribe(x => {
  //         x.map(x => {
  //           if (x.id === id) {
  //             this.selectedJob = x;
  //           }
  //         });
  //       });
  //     }
  //   },
  //     error => {
  //       console.error(error);
  //     });
  // }

  /**
   * Creates a new board for the current user
   */
  async createJob(data: Job) {
    const docId = this.db.createId();
    const user = await this.afAuth.currentUser;
    return this.db.collection('jobs').doc(docId).set({
      ...data,
      id: docId,
      uid: user.uid,
    });
  }

  /**
   * Get a single board
   */
  getJob(jobId: string) {
    return this.db
      .collection('jobs')
      .doc(jobId);
  }

  /**
   * Updates a board/job
   */
  updateJob(job: Job) {
    return this.db
      .collection('jobs')
      .doc(job.id)
      .update(job);
  }

  /**
   * Run a batch write to change the priority of each board for sorting
   */
  sortJobs(jobs: Job[]) {
    const db = firebase.firestore();
    const batch = db.batch();
    const refs = jobs.map(j => db.collection('jobs').doc(j.id));
    refs.forEach((ref, idx) => batch.update(ref, { order: idx }));
    batch.commit();
  }

  /**
   * Delete board
   */
  deleteJob(jobId: string) {
    return this.db
      .collection('jobs')
      .doc(jobId)
      .delete();
  }
}
