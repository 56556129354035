import { Job } from '../models/job.model';

export namespace JobActions {

  export class GetAll {
    public static readonly type = '[Jobs] Get All';
    constructor(public payload: string) { }
  }
  export class GetAllFailure {
    public static readonly type = '[Jobs] Get All Failure';
    constructor(public payload: string) { }
  }

  export class SetSelectedJob {
    public static readonly type = '[Jobs] Set Selected Job';
    constructor(public payload: string) { }
  }

  // export class GetSingle {
  //   public static readonly type = '[Jobs] Get User';
  //   constructor(public payload: string) { }
  // }

  export class Create {
    public static readonly type = '[Jobs] Create';
    constructor(public payload: Job) { }
  }

  export class Update {
    public static readonly type = '[Jobs] Update Job';
    constructor(public payload: Job) { }
  }

  export class Delete {
    public static readonly type = '[Jobs] Delete';
    constructor(public payload: string) { }
  }
}
