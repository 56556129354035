import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormGroup, FormControl } from '@angular/forms';
import { Story } from '../../shared/state/models/story.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DatePipe } from '@angular/common';
import { JobService } from '../../services/job.service';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { StoryActions } from '../state/stories/stories.actions';
import { Select } from '@ngxs/store';
import { UserState } from '../state/user/user.state';
import { Observable } from 'rxjs';
import { JobState } from '../state/jobs/jobs.state';
import { Job } from '../state/models/job.model';

export interface ViewStory extends Story {
  mode: 'edit' | 'view' | 'new';
}

@Component({
  selector: 'ngx-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss'],
})
export class StoryComponent implements OnInit {
  @Dispatch()
  newStory = ({jobId, data}) => new StoryActions.Create({jobId, data})
  @Dispatch()
  updateStory = (data: Story) => new StoryActions.Update(data)
  @Dispatch()
  deleteStory = ({uuid, storyId}) => new StoryActions.Delete({uuid, storyId})

  @Select(UserState.getCurrentUser) user$: Observable<any>;
  @Select(JobState.selectedJob) selectedJob$: Observable<Job>;

  @Input() public selectedStory: ViewStory;
  public Editor = ClassicEditor;
  storyForm: FormGroup;
  selectedType = 'text';
  selectedColor;

  constructor(public dialogRef: NbDialogRef<any>, public datepipe: DatePipe, public jobService: JobService) {}

  ngOnInit(): void {
    if (this.selectedStory !== undefined && this.selectedStory['label']) {
      this.selectedColor = this.selectedStory['label'] || 'none';
    } else {
      this.selectedColor = this.selectedStory?.title || 'none';
    }
    this.buildForm();
  }

  public buildForm() {
    this.storyForm = new FormGroup({
      title: new FormControl(this.selectedStory?.title),
      summary: new FormControl(this.selectedStory['description'] ||
                                this.selectedStory?.summary || 'Tell Your Story...'),
      createdDate: new FormControl(this.selectedStory?.previousDate || new Date()),
      label: new FormControl(this.selectedStory['label'] || this.selectedStory?.title || 'none'),
      type: new FormControl(this.selectedStory?.type || 'text'),
      videoUrl: new FormControl(this.selectedStory?.videoUrl || null),
      photoUrl: new FormControl(this.selectedStory?.photoUrl || null),
      active: new FormControl(this.selectedStory?.active || true),
      audioURL: new FormControl(this.selectedStory?.audioURL || null),
      color: new FormControl(this.selectedStory?.color || 'none'),
      experience: new FormControl(this.selectedStory?.experience || null),
      id: new FormControl(this.selectedStory?.id || null),
      jobId: new FormControl(this.selectedStory?.jobId || null),
      order: new FormControl(this.selectedStory?.order || 0),
      uid: new FormControl(this.selectedStory?.uid || null),
      public: new FormControl(this.selectedStory?.public || true),
    });
  }

  onSubmit(jobId: string) {
    const data: Story = this.storyForm.value;
    if (this.selectedStory?.mode === 'edit') {
      this.updateStory(data);
    } else {
      this.newStory({jobId: jobId, data});
    }
    this.dialogRef.close();
    // fire success message
  }

  // TODO: USE STORY ID
  onDelete() {
    const data = this.storyForm.value;
    this.deleteStory({ uuid: this.selectedStory.uid, storyId: this.selectedStory.id });
    this.dialogRef.close();
    // fire successful delete msg
  }

  get getStoryDate() {
    const rawDate = this.storyForm.value.createdDate;
    const formattedDate = this.datepipe.transform(rawDate, 'yyyy-MM-dd');
    return formattedDate;
  }

  setFormData(formProperty: string, formData: any) {
    let data;
    if (formData.editor) {
      data = formData.editor.getData();
    } else {
      data = formData;
    }
    this.storyForm.get(formProperty).patchValue(data);
  }

  cancelStory() {
    this.dialogRef.close();
  }

  // TODO: FIX PATH TO IMAGR, CHECK IT IS CORRECT
  setPhotoUrl($event) {
    if ($event) {
      this.storyForm.get('photoUrl').patchValue($event);
    }
  }
}
