import { Profile } from '../models/profile.model';

export namespace ProfileActions {

  export class GetUserProfile {
    public static readonly type = '[Profile] Get Profile';
    // payload: user_id
    constructor(public payload: string) { }
  }

  export class UpdateUserProfile {
    public static readonly type = '[Profile] Update Profile';
    // payload: Profile
    constructor(public payload: Profile) { }
  }

  export class GetUserProfileFailure {
    public static readonly type = '[Profile] Get Profile Failure';
    constructor(public payload: string) { }
  }

  export class UpdateUserProfileFailure {
    public static readonly type = '[Profile] Update Profile Failure';
    constructor(public payload: string) { }
  }
}
