<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><div>R</div><div class="bolded">2</div><i *ngIf="user && user.role === 'admin'">ADMIN</i></a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>

    <nb-action class="control-item">
      <!-- <nb-select [selected]="currentOs" (selectedChange)="changeOs($event)" size="small" status="primary" shape="round">
        <nb-option *ngFor="let os of osArray" [value]="os.name"> {{ os.name }}</nb-option>
      </nb-select> -->
    </nb-action>

    <!-- <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action> -->
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               onlyPicture
               [picture]="user?.picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
