import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import { UserActions } from './user.actions';

export interface UserStateModel {
  user: {
    aud?: string;
    auth_time?: number;
    email: string;
    email_verified?: boolean;
    exp?: number;
    firebase?: {identities: any, sign_in_provider: string};
    iat?: number;
    iss?: string;
    name?: string;
    picture?: string;
    sub?: string;
    user_id?: string;
  };
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: {
      email: 'test@test.com',
    },
  },
})
@Injectable()
export class UserState {

  @Selector()
  public static getCurrentUser(state: UserStateModel) {
    return state.user;
  }

  @Action(UserActions.SignIn)
  public signIn(ctx: StateContext<UserStateModel>, { payload }) {
    const userModel = ctx.getState();
    ctx.setState({
      ...userModel,
      user: payload,
    });
  }

  @Action(UserActions.SignOut)
  public signOut(ctx: StateContext<UserStateModel>) {
    const userModel = ctx.getState();
    ctx.setState({
      ...userModel,
      user: null,
    });
  }
}
