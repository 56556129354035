import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MINI_ACCOUNT_MENU } from '../../../menu/mini-menus/mini-account-menu';
import { FeaturedService } from 'app/services/featured.service';
import { UserService } from '../../../services/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Board } from 'app/models/board.model';

import { UserActions } from '../../../shared/state/user/user.actions';
import { Select, Store } from '@ngxs/store';
import { JobActions } from '../../../shared/state/jobs/jobs.actions';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  current: any;

  userMenu = MINI_ACCOUNT_MENU;

  constructor(private sidebarService: NbSidebarService, private menuService: NbMenuService,
    private featuredService: FeaturedService, private authService: NbAuthService, private userService: UserService,
    public afAuth: AngularFireAuth, private breakpointService: NbMediaBreakpointsService,
    private router: Router, private store: Store) {

    this.authService.onTokenChange()
    .pipe(takeUntil(this.destroy$))
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          this.user = token.getPayload();
          this.userService.setCurrentUser(this.user);
          this.store.dispatch(new UserActions.SignIn(this.user));
        }

      });
  }

  ngOnInit() {
    if (!this.user?.email || this.user.email === '') {
      this.router.navigate(['auth/login']);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeOs(opSys: string) {
    this.featuredService.setOs(opSys);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
