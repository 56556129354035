import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FeaturedService {

    operatingSystem$ = new BehaviorSubject('Windows');
    category$ = new BehaviorSubject('All');
    url = 'https://zeus.api.repairtools.net/api/';

    public getFeaturedPrograms$ = combineLatest([this.operatingSystem$, this.category$]).pipe(
      map(([OS, CAT]) => {
        const query = this.url + `Promoted/${OS}?Category=${CAT}`;
        return this.http.get<any>(query);
      }),
    );

    constructor(private http: HttpClient) {
    }

    getFeatured(category: string): Observable<any> {
      if (category) {
        this.category$.next(category);
      }
      return this.http.get<any>(this.url);
    }

    setOs(term: string) {
        this.operatingSystem$.next(term);
    }

    setCategory(term: string) {
        this.category$.next(term);
    }
}
